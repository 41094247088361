<template>
  <Nav></Nav>
  <div class="nodes-box container mx-auto p-4">
    <div class="nodes-text-white text-center text-4xl">How to play</div> <br />
    <div class="nodes-text-white text-left text-sm">
      Objective of the game is to capture as many control points (yellow dots) as possible, you capture a
      control point if one of your nodes (blue triangles) is in weapons range (blue circle) of the control
      point. <br />
      You have two types of node, core nodes (filled) and battle nodes (outline) battle nodes can fight enemy
      nodes (red triangles) or be upgraded in addition core nodes are able to build new nodes and research
      new tech. <br />
      Only idle nodes (green outline) will participate in battle. <br />
      A game lasts for 20 mins, research takes 20 secs, building a node takes 40 secs, upgrading a battle node
      to a core node takes 40 secs, retrofitting with new shields or weapons takes 5 secs, nodes fire once
      every 5 secs. <br />
      When researching, you pick an existing shield or weapon to improve upon; you can then select for power
      (increased damage/health/range/regeneration) or efficiency (improving the damage types caused/resisted)

    </div>
  </div>
</template>

.instructions .header {
text-align: left;
font-size: 18px;
font-weight: bold;
color: rgba(255,255,255,0.75);
text-shadow: 0 0 10px rgba(255,0,0,0.95);
display: inline-block;
margin-left: 8px;
}

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'

export default {
  name: 'HowToPlay',
  components: {
    Nav
  }
}
</script>
